<template>

  <div>


    <div class="row">
      <div class="col-md-12 mt-1">
        <b-card no-body  class="mb-0">
        

 

  
        <b-table
            class="position-relative"
            responsive
            :fields="tableColumns"
            :items="invoices"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
        >


            <template #cell(actions)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  size="sm"
                  @click="downloadPdf(data.item.id)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Télécharger</span>
                </b-button>
            </template>

        </b-table>

           <div class="mx-2 mb-2">
            <b-row>
    
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
    
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalInvoices"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
    
              </b-col>
    
            </b-row>
          </div>

      </b-card>
    </div>
  </div>

</div>

</template>


<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
    }  from 'bootstrap-vue'

  import vSelect from 'vue-select'
  import { avatarText } from '@core/utils/filter'
  import { mapGetters, mapActions } from "vuex"

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      ToastificationContent,

    

    },

    data() {
        return {
            tableColumns : [
                { label: 'N° Facture', key: 'num_facture', sortable: true },
                { label: 'Type', key: 'type', sortable: true },
                { label: 'Date', key: 'date', sortable: true },
                { label: 'HT', key: 'ht', sortable: true },
                { label: 'TTC', key: 'ttc', sortable: true },
                { label: 'Actions', key: 'actions' },
            ],
            isSortDirDesc : true,
            perPage : 10,
            totalInvoices : 0,
            currentPage : 1,
            perPageOptions : [10, 25, 50, 100],
    
            avatarText,
            f_search:"",
        
        }
    },

    computed: {

      ...mapGetters('invoices', {'invoices' : 'GET_ITEMS'}),

    },

    methods: {
        ...mapActions('invoices', {'fetchInvoices' : 'fetchItems'}),
         downloadPdf(id) {
          let a = document.createElement("a");
          a.setAttribute("href", `https://hellodepann.fr/account/factures/${id}/download`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
    
    },

    created() {
      this.fetchInvoices()
    },

    watch: {

    }
  }
</script>

<style lang="scss" scoped>
  .services:not(:first-child) {
    margin-left: 5px;
  }

  .per-page-selector {
      width: 90px;
  }

  .media {
      display: flex;
      align-items: center;
  }

  .media-aside {
      margin-right: 0.5rem;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>


